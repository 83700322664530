var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', {
    staticClass: "text-h4 secondary primary--text"
  }, [_vm._v(_vm._s(_vm.$t('locales.add.title')))]), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-form', {
    ref: "addLocaleForm",
    attrs: {
      "id": "add-locale-form",
      "lazy-validation": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.addLocale.apply(null, arguments);
      }
    }
  }, [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('CommonAutocomplete', {
    attrs: {
      "items": _vm.localeNames,
      "outlined": "",
      "rules": [_vm.isRequired(_vm.selectedLocale, _vm.$t('form.field.newLocale'))],
      "placeholder": _vm.$t('form.field.newLocale'),
      "prepend-inner-icon": _vm.icons.mdiEarth
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('b', [_vm._v(" " + _vm._s(item) + " ")])];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-list-item-icon', {
          staticClass: "mr-3"
        }, [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiEarth))])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.getLocaleCode(item)))])], 1)];
      }
    }]),
    model: {
      value: _vm.selectedLocale,
      callback: function callback($$v) {
        _vm.selectedLocale = $$v;
      },
      expression: "selectedLocale"
    }
  })], 1)], 1), _vm._l(_vm.implementedLocales, function (locale) {
    return _c('v-row', {
      key: locale,
      attrs: {
        "dense": ""
      }
    }, [_c('v-col', [_c('v-text-field', {
      attrs: {
        "label": _vm.$t("locale.".concat(locale)),
        "rules": [_vm.isRequired(_vm.newTranslations[locale], _vm.$t("locale.".concat(locale)))],
        "outlined": ""
      },
      scopedSlots: _vm._u([{
        key: "prepend-inner",
        fn: function fn() {
          return [_vm.resolveCountry(locale) !== '' ? _c('CommonFlagIcon', {
            attrs: {
              "locale": _vm.resolveCountry(locale)
            }
          }) : _vm._e(), _vm.resolveCountry(locale) === '' ? _c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiEarth))]) : _vm._e()];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.newTranslations[locale],
        callback: function callback($$v) {
          _vm.$set(_vm.newTranslations, locale, $$v);
        },
        expression: "newTranslations[locale]"
      }
    })], 1)], 1);
  }), _vm.selectedLocale ? _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.selectedLocale,
      "rules": [_vm.isRequired(_vm.newTranslations[_vm.getLocaleCode(_vm.selectedLocale)], _vm.selectedLocale)],
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function fn() {
        return [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiEarth))])];
      },
      proxy: true
    }], null, false, 2559963552),
    model: {
      value: _vm.newTranslations[_vm.getLocaleCode(_vm.selectedLocale)],
      callback: function callback($$v) {
        _vm.$set(_vm.newTranslations, _vm.getLocaleCode(_vm.selectedLocale), $$v);
      },
      expression: "newTranslations[getLocaleCode(selectedLocale)]"
    }
  })], 1)], 1) : _vm._e()], 2)], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "text": "",
      "elevation": "0"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('form.action.cancel')))]), _c('v-spacer'), _c('v-btn', {
    staticClass: "ml-2 rounded-lg",
    attrs: {
      "elevation": "0",
      "color": "primary",
      "type": "submit",
      "form": "add-locale-form",
      "loading": _vm.isLoading
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.action.addLocale')) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }