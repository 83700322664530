
import { computed, defineComponent, ref } from '@vue/composition-api'
import { mdiEarth } from '@mdi/js'
import ISO6391 from 'iso-639-1'

import CommonFlagIcon from '@/components/common/CommonFlagIcon.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { useLocaleActions, useNotify } from '@/store'

import { resolveCountry } from '@/plugins/i18n'

import { translationsApi } from '@/api'

import { ValidationForm } from '@/utils/types/validation'
import { isRequired } from '@/utils/validation'

export default defineComponent({
  name: 'AdminAddLocale',
  components: {
    CommonFlagIcon,
    CommonAutocomplete,
  },
  setup: (_, { emit, root }) => {
    const { updateTranslation, isLoading } = translationsApi.useUpdateTranslation()

    const { addLocale: addLocaleToStore } = useLocaleActions()

    const { addNotification } = useNotify()

    const addLocaleForm = ref<ValidationForm | null>(null)
    const selectedLocale = ref<string | null>(null)
    const localeNames = ISO6391.getAllNames().sort()
    const getLocaleCode = (name: string) => {
      return ISO6391.getCode(name)
    }

    const implementedLocales = computed<string[]>(() => root.$store.state.locale.implementedLocales)

    const newTranslations = ref<{ [key: string]: string }>({})

    const addLocale = () => {
      if (addLocaleForm.value?.validate() && selectedLocale.value) {
        const newLocale = getLocaleCode(selectedLocale.value)
        if (newLocale) {
          const promises = Object.entries(newTranslations.value).map(([key, value]) =>
            updateTranslation({ key: `locale.${newLocale}`, value: value, locale: key })
          )

          Promise.all(promises)
            .then(() => {
              addNotification({
                type: 'success',
                text: root.$t('locales.add.success') as string,
              })

              addLocaleToStore(newLocale)

              emit('locale-added', newLocale)
            })
            .catch((error) => {
              error.userMessage = root.$t('locales.add.error')

              throw error
            })
        }
      }
    }

    return {
      icons: { mdiEarth },
      addLocaleForm,
      isRequired,
      selectedLocale,
      localeNames,
      implementedLocales,
      newTranslations,
      addLocale,
      getLocaleCode,
      resolveCountry,
      isLoading,
    }
  },
})
